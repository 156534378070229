
#configurations-form .financial-form-main-data  .hiperlink:hover {
  text-decoration: underline;
  cursor: pointer;
}

#configurations-form .financial-form-main-data .toggle-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content
}

#configurations-form .financial-form-main-data .toggle-column  span{
  color: red;
}

.hiperlink{
  font-size: 12px;
  color: #428bca;
  margin-left: 5px;
}