body {
  background-color: #f8fafb;
}

.income-statement__header-rectangle {
  position: relative;
  background-color: #1d7391;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 45px;
}

.income-statement__title {
  color: #1d7391;
  font-size: 18px;
  font-weight: bold;
  margin-left: 20px;
  margin-top: 20px;
}

.income-statement__line {
  width: 97%;
  height: 1px;
  background-color: #dad8d8;
}

.income-statement__footer-rectangle {
  position: fixed;
  margin-top: 20%;
  bottom: 0;
  background-color: #1d7391;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 45px;
}

.income-statement__card {
  background-color: #fff;
  border: 1px solid;
  border-color: #d6d6d6;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 80%;
  margin-top: 20px;
  margin-bottom: 65px;
}

.income-statement__card-div {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
  padding: 0 10px 0 10px;
}

.income-statement__card-info {
  display: flex;
  justify-content: space-between;
  background-color: #f9f9f9;
  width: 100%;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
}

.income-statement__card-info section {
  display: flex;
  color: #444444;
  font-size: 20px;
  font-weight: bold;
  padding: 0 20px 0 20px;
}

.income-statement__card__sub-items__item {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-inline: 2%;
}

.income-statement__card__sub-items__item span {
  font-size: 12px;
  color: #444444;
  font-weight: bold;
}

.title_grouped_sales {
  font-family: Arial, sans-serif;
  font-size: 10px;
  font-weight: bold;
  font-style: italic;
  display: flex;
}

.borderBottom {
  border-bottom: 1px solid #d6d6d6;
}

.collapsible-container {
  border: 1px solid #1d7391;
}
