#configurations-form .printing-sheets-form-main-data  .field-hiperlink {
  position: absolute;
  right: 20px;
  top: 10px;
  color: #428bca;
}

#configurations-form .printing-sheets-form-main-data  .field-hiperlink:hover {
  text-decoration: underline;
  cursor: pointer;
}

#configurations-form .printing-sheets-form-main-data .toggle-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content
}

#configurations-form .printing-sheets-form-main-data .toggle-column  span{
  color: red;
}