.divgray{
    background-color: #f7f6f6;
     padding: 20px;
     
}

#print {
    /* width: 210mm; */
    /* height: 280mm; */
    margin-top: 40px;
    background-color: white;
    padding: 20px;
}