.container-pdv{
    background: #f4f4f4;
    height: 100vh;
}
.header-pdv {
    width: 100%;
    height: 50px;
    background: #1D7391;
    padding: 5px 15px;
    display: flex;
    align-items: center;
}

.header-pdv img {
    height: 100%;
}

.container-user {
    height: 90px;
    width: 250px;
    position: fixed;
    bottom: 0;
    left: 0;
    border-radius: 0px 8px 0px 0px;
    padding: 10px;
    background: #1D7391;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container-user span {
    font-size: 12px;
}

.container-user p {
    font-weight: bold;
}

.container-user span#link-user {
    cursor: pointer;
    text-decoration: underline;
}

#pdv-footer {
    background-color: white;
    height: 75px;
    box-shadow: 20px 13px 20px 0px;
    display: flex;
    justify-content: flex-end;
    padding: 15px 25px;
}

#pdv-footer section button {
    margin: 0px 10px;
    height: 100%;
    outline: none;
    color: white;
    border-radius: 5px;
    border: none;
    padding: 10px 20px;
    box-shadow: 20px 13px 20px 0px;
    font-weight: 500;
    font-size: 16px;
}

#pdv-footer section button:disabled {
    opacity: 0.7;
}

#pdv-footer .success {
    background-color: #5cb85c;
}

#pdv-footer .success:hover {
    background-color: #51a751;
}

#pdv-footer .danger {
    background-color: #d9534f;
}

#pdv-footer .danger:hover {
    background-color: #bf4743;
}

#pdv-footer .warning {
    background-color: #f0ad4d;
}

#pdv-footer .warning:hover {
    background-color: #dd9e45;
}

#pdv-footer .info {
    background-color: #40729b;
}

#pdv-footer .info:hover {
    background-color: #3a688d;
}

#pdv-footer .cashier {
    background-color: #5bc0de;
    width: 120px;
}

#pdv-footer .cashier:hover {
    background-color: #66cceb;
}


#pdv-footer .back-button {
    min-width: 130px;
}