.external-workshop-panel {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;

  background-color: #eceff1;

  box-sizing: border-box;
}

.external-workshop-panel__content {
  padding: 3%;
}

.external-workshop-panel__maintenance-title {
  padding: 1.5% 2.5%;

  border-bottom: 1px solid #d6d6d6;

  font-size: 18px;
  font-weight: bold;
  color: #1d7391;
}

.external-workshop-panel__maintenance-subtitle {
  padding: 0 2.5%;

  font-size: 18px;
  font-weight: bold;
  color: #1d7391;
}

/* table */

.external-workshop-panel__table {
  width: 100%;
  height: 100%;

  border: 1px solid #d6d6d6;
  border-radius: 4px;

  background-color: #fff;
  color: #000;
}

.external-workshop-panel__table__title {
  padding: 5px;

  font-size: 14px;
  font-weight: bold;
  color: #1d7391;
}

.external-workshop-panel__table__text {
  padding: 5px;

  font-size: 14px;
  color: #000;
}

.external-workshop-panel__table__basic-info--mobile {
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 10px;

  padding: 0 2.5%;

  width: 95%;
  margin: auto;

  border-bottom: 1px solid #ccc;
}

.external-workshop-panel__table__basic-info-content--mobile {
  display: grid;
  grid-template-columns: 1fr 4fr;
  gap: 10px;

  padding: 0 2.5%;

  width: 95%;
  margin: auto;
}

.external-workshop-panel__table__status-info--mobile {
  display: grid;
  grid-template-columns: 4fr 3fr;
  gap: 10px;

  padding: 0 2.5%;

  width: 95%;
  margin: auto;

  border-bottom: 1px solid #ccc;
}

.external-workshop-panel__table__status-info-content--mobile {
  display: grid;
  grid-template-columns: 4fr 3fr;
  gap: 10px;

  padding: 1.5% 2.5%;

  width: 95%;
  margin: auto;
}

.external-workshop-panel__table__services-info--mobile {
  padding: 0 2.5%;

  width: 95%;
  margin: auto;

  border-bottom: 1px solid #ccc;
}

.external-workshop-panel__table__services-info-content--mobile {
  padding: 1.5% 2.5%;

  width: 95%;
  margin: auto;
}

.external-workshop-panel__table__services-info-list--mobile {
  width: 95%;

  margin: 3% 0;

  display: grid;
  grid-template-columns: 1fr;
}

.external-workshop-panel__table__services-info-visualize-OS--mobile {
  display: flex;
  justify-content: center;
  align-items: center;
}

.external-workshop-panel__table__services-info-visualize-OS--mobile a {
  margin-top: 3%;

  font-size: 16px;
  font-weight: bold;
  color: #008db1;

  text-decoration: underline;
  color: #1d7391;
}

.external-workshop-panel__table__employee-card-list--mobile {
  display: grid;
  grid-template-columns: 1fr 1fr;

  border-radius: 3px;
  padding: 8px;

  margin-bottom: 5px;

  width: 95%;
  margin: auto;
  gap: 10px;

  font-size: 12px;
  font-weight: 500;
  /* white-space: nowrap; */

  cursor: pointer;

  transition: 0.5s;
}

.external-workshop-panel__table__employee-card--mobile {
  background-color: #428bca;
  color: #fff;
  border-radius: 0.5rem;
  padding: 10px;

  display: flex;
  justify-content: left;
  gap: 10px;
}

.external-workshop-panel__table__maintenance-status {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.external-workshop-panel__table__status-button {
  margin-top: 10px;

  margin-left: auto;
  margin-right: auto;

  height: 40px;
  border: none;
  font-size: 14px;
  color: #fff;
  width: 100%;
  border-radius: 3px;
}

.external-workshop-panel__table__elapsed-time {
  display: block;
  font-size: 10px;
  margin-top: 8px;

  width: 100%;
}

.external-workshop-panel__table__updatedAtInfo {
  display: flex;
  font-size: 10px;

  justify-content: flex-end;

  margin-top: 15px;
  margin-left: auto;
  margin-right: auto;

  width: 95%;

  border-top: 1px solid #ccc;

  color: #1d7391;
  font-weight: bold;
}

/* header */

.external-workshop-panel__header {
  width: 100%;
  height: 50px;
  background: #1d7391;
}

/* sobreRodasLink */

.external-workshop-panel__sobreRodas-link {
  width: 95%;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 10px;

  flex-direction: column;
}

.external-workshop-panel__sobreRodas-link a {
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
}

.external-workshop-panel__sobreRodas-link img {
  width: 30%;
}

/* footer */

.external-workshop-panel__footer {
  width: 100%;
  height: auto;
  padding: 5px 0;
  background: white;

  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: auto;
}

.external-workshop-panel__footer__os-dig-logo {
  position: relative;

  width: 30%;
}

/* web */

.employee-table-card {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;

  border-radius: 3px;
  padding: 8px;

  margin-bottom: 5px;

  background-color: #428bca;
  color: #fff;

  font-size: 12px;
  font-weight: 500;
  white-space: nowrap;

  -webkit-box-shadow: 0px 1px 19px 1px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 1px 19px 1px rgba(0, 0, 0, 0.2);

  cursor: pointer;

  transition: 0.5s;
}

.workshop-panel-maintenances__external {
  width: 100%;
}

.workshop-panel-maintenances__external table {
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 10px;

  display: flex;
  flex-direction: column;
}

.workshop-panel-maintenances__external table thead tr,
.workshop-panel-maintenances__external table tbody tr {
  width: 100%;
  display: flex;
  gap: 30px;
}

.workshop-panel-maintenances__external table thead tr {
  padding: 5px;
  border-bottom: 1px solid #ccc;

  font-size: 14px;
  font-weight: bold;
  color: #1d7391;
}

.workshop-panel-maintenances__external table hr {
  width: 100%;
  margin: 0;
}

.workshop-panel-maintenances__external table tbody tr {
  min-height: 80px;
  margin-top: 12px;
  border-bottom: 1px solid #d6d6d6;

  display: flex;
  justify-content: center;
  align-items: center;
}

.workshop-panel-maintenances__external table tr p {
  margin: 0;
  font-size: 12px;
  color: #444444;
}

.workshop-panel-maintenances__external table tr ul {
  padding-inline-start: 20px;
  font-size: 12px;
}

.workshop-panel-maintenances__external .service-order-code {
  font-size: 16px;
  font-weight: bold;
  color: #008db1;
  text-decoration: underline;

  background: none;
  border: none;
}

.workshop-panel-frame__external {
  display: none;
  flex-direction: column;
}

.workshop-panel-frame-title__external {
  padding: 10px;
  border-bottom: 1px solid #d6d6d6;

  font-size: 18px;
  font-weight: bold;
  color: #1d7391;
}

.workshop-panel-frame-content__external {
  border: 1px solid #d6d6d6;
  border-radius: 4px;

  background-color: #fff;
  color: #000;
}

.workshop-panel-employees__external {
  height: 55vh;
  overflow: auto;
  padding: 20px;

  display: flex;
  flex-direction: column;
  gap: 8px;
}

@media (min-width: 550px) {
  .external-workshop-panel__footer__os-dig-logo {
    position: relative;

    width: 20%;
  }

  .external-workshop-panel__sobreRodas-link img {
    width: 20%;
  }
}

@media (min-width: 768px) {
  .external-workshop-panel__maintenance-title {
    padding: 0;
  }

  .external-workshop-panel__table__services-info-list--mobile {
    grid-template-columns: 1fr 1fr;
  }

  .external-workshop-panel__sobreRodas-link {
    /* justify-content: normal; */
    align-items: flex-start;
  }

  .external-workshop-panel__footer__os-dig-logo {
    position: relative;

    width: 150px;
  }

  .external-workshop-panel__sobreRodas-link img {
    width: 200px;
  }
}

@media (min-width: 920px) {
  /* setting mobile table to display none */

  .external-workshop-panel__table {
    display: none;
  }

  .workshop-panel-frame__external {
    display: flex;
  }
}
