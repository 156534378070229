#select-products-step {
  display: grid;
  flex: 1 1;
  grid-template-columns: 0.7fr 1fr;
  grid-gap: 2rem;
  gap: 2rem;
  height: 100%;
}

#select-products-step .product-list {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#select-products-step .product-list footer {
  display: flex;
  justify-content: flex-end;
}

#select-products-step .product-list footer .totals-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-end;
}

#select-products-step .product-list footer .totals-wrapper {
  width: 200px;
  padding: 0px 5px;
}

#select-products-step .product-list footer .totals-wrapper>div {
  display: flex;
  justify-content: space-between;
}

#select-products-step .container-left {
  padding: 1%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 2rem;
}

#select-products-step .container-left input,
textarea {
  color: #606060;
  font-size: 16px;
  border: 2px solid #c0c0c0;
  border-radius: 1px;
}

#select-products-step .container-left .search-input {
  display: flex;
  flex-direction: column;
}

#select-products-step .container-left .search-input input {
  width: 100%;
}

#select-products-step .container-left .search-input button {
  width: 100%;
  margin-top: 10px;
}

.search-input-fields {
  display: flex;
  flex-direction: row;
  gap: 5px;
}

.search-input-code {
  width: 65%;
}

.search-input-vehicle {
  width: 35%;
}

#select-products-step .values {
  display: flex;
}

#select-products-step .values div {
  width: 100%;
  text-align: left;
}

#select-products-step .values input {
  width: 100%;
}

#select-products-step .obs-pdv {
  display: flex;
  width: 100%;
  align-items: center;
}

#select-products-step .obs-pdv textarea {
  width: 100%;
  height: 45px;
  padding: 5px 10px;
  resize: none;
  border: 2px solid #c0c0c0;
  border-radius: 2px;
}

#select-products-step .obs-pdv textarea:focus {
  border: 2px solid #c0c0c0 !important;
  border-radius: 2px;
}

#select-products-step .client-pdv-info {
  display: grid;
  grid-template-columns: 0.7fr 1fr;
  align-content: end;
  gap: 1.5rem;
}

#select-products-step div input {
  width: 100%;
}

#select-products-step .client-pdv-cpf {
  width: 75%;
}

.btn-modal-client button {
  outline: none;
  color: white;
  border-radius: 5px;
  border: none;
  padding: 10px 20px;
  box-shadow: 6px 3px 20px 0px;
  font-weight: 500;
  font-size: 16px;
  width: 150px;
}

.btn-modal-client button:disabled {
  opacity: 0.7;
}

.btn-modal-client .danger {
  background-color: #d9534f;
}

.btn-modal-client .danger:hover {
  background-color: #bf4743;
}

.text-teste {
  font-size: 13px;
}

.btn-search-add {
  width: 100%;
}
